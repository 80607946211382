<template>
    <ul class="flex overflow-x-scroll text-sm hideScrollbar">
        <li
            v-for="(price, index) in choosePrice"
            :key="price.value"
            :class="[
                index !== choosePrice.length - 1 ? 'mr-2' : '',
                choosePrice.length === 1
                    ? 'flex justify-center min-w-[100px] max-w-[100px]'
                    : 'w-full  ',
            ]"
            class="mt-2 flex-1 md:min-w-[100px] md:max-w-[100px]"
        >
            <button
                :class="[
                    currentPrice == price.value
                        ? 'bg-red-500 text-white'
                        : 'text-red-500',
                    choosePrice.length === 1
                        ? 'min-w-[100px] max-w-[100px]'
                        : 'w-full',
                ]"
                class="px-2 py-1 text-xs border border-red-500 rounded whitespace-nowrap md:w-full"
                @click.prevent="changePrice(price.value)"
            >
                {{ price.label }}
            </button>
        </li>
    </ul>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";

// 金額顯示逗號規則
import { formatCurrency } from "@/service/anyService";
import { isEmpty } from "@/service/anyService";
const __sfc_main = {};
__sfc_main.props = {
  // 每小時單價
  hourPrice: {
    type: [Number, String],
    default: 0
  },
  // 判斷是小時或天數計價
  unit: {
    type: String,
    default: "hour"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;

  // 選中金額
  const currentPrice = ref(isEmpty(props.hourPrice) ? 0 : props.hourPrice);
  watch(() => currentPrice.value, val => {
    // 回傳 目前選擇每小時單價 每母組件
    emit("update:hourPrice", val);
  });

  // 預設可選擇金額範圍
  const defaultPriceChoose = ref([{
    label: `$${formatCurrency(2500)}`,
    value: 2500
  }, {
    label: `$${formatCurrency(3000)}`,
    value: 3000
  }, {
    label: `$${formatCurrency(3500)}`,
    value: 3500
  }, {
    label: "由服務商報價",
    value: 0
  }]);

  // 可選擇金額
  const choosePrice = ref([{
    label: `$${formatCurrency(2500)}`,
    value: 2500
  }, {
    label: `$${formatCurrency(3000)}`,
    value: 3000
  }, {
    label: `$${formatCurrency(3500)}`,
    value: 3500
  }, {
    label: "由服務商報價",
    value: 0
  }]);
  watch(() => props.unit, val => {
    if (val === "hour" && props.hourPrice !== 0) {
      choosePrice.value = [{
        label: `$${formatCurrency(2500)}`,
        value: 2500
      }, {
        label: `$${formatCurrency(3000)}`,
        value: 3000
      }, {
        label: `$${formatCurrency(3500)}`,
        value: 3500
      }, {
        label: "由服務商報價",
        value: 0
      }];
      return;
    }
    if (props.hourPrice !== 0) {
      choosePrice.value = [{
        label: `$${formatCurrency(25000)}`,
        value: 25000
      }, {
        label: `$${formatCurrency(30000)}`,
        value: 30000
      }, {
        label: `$${formatCurrency(35000)}`,
        value: 35000
      }, {
        label: "由服務商報價",
        value: 0
      }];
    }
    if (props.hourPrice === 0) {
      choosePrice.value = [{
        label: "自行定價",
        value: props.unit === "hour" ? Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE) : Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY)
      }];
    }
  });
  watch(() => props.hourPrice, val => {
    currentPrice.value = val;
    if (val === 0) {
      choosePrice.value = [{
        label: "自行定價",
        value: props.unit === "hour" ? Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE) : Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY)
      }];
    } else {
      choosePrice.value = defaultPriceChoose.value;
    }
    if (props.unit === "hour" && props.hourPrice !== 0) {
      choosePrice.value = [{
        label: `$${formatCurrency(2500)}`,
        value: 2500
      }, {
        label: `$${formatCurrency(3000)}`,
        value: 3000
      }, {
        label: `$${formatCurrency(3500)}`,
        value: 3500
      }, {
        label: "由服務商報價",
        value: 0
      }];
      return;
    }
    if (props.hourPrice !== 0) {
      choosePrice.value = [{
        label: `$${formatCurrency(25000)}`,
        value: 25000
      }, {
        label: `$${formatCurrency(30000)}`,
        value: 30000
      }, {
        label: `$${formatCurrency(35000)}`,
        value: 35000
      }, {
        label: "由服務商報價",
        value: 0
      }];
    }
  });

  /**
   * 更換每小時單價
   * @param { type Number(數字) } price 每小時單價
   */
  function changePrice(price) {
    currentPrice.value = price;
  }
  return {
    currentPrice,
    choosePrice,
    changePrice
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
/**
隱藏滾動條方法
 */
.hideScrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}
</style>
