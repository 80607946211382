<template>
    <el-dialog
        :visible.sync="showDialog"
        :close-on-click-modal="true"
        :modal-append-to-body="true"
        :close-on-press-escape="true"
        @open="openDialog"
        @close="closeDialog"
    >
        <div class="py-5 text-2xl font-bold text-center text-white bg-black">
            新增即刻快閃
        </div>
        <div v-if="!showConfirmDetail">
            <el-form ref="userForm" :model="userForm" :rules="userFormRules">
                <el-form-item v-loading="loading" prop="mobile">
                    <div class="flex">
                        <el-select
                            v-model="userForm.countryCode"
                            class="flex-shrink w-40"
                            placeholder="國碼"
                        >
                            <el-option
                                v-for="item in ['886']"
                                :key="item"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <el-input
                            v-model="userForm.mobile"
                            class="flex-grow"
                            placeholder="請輸入會員手機號碼"
                            @input="onInput"
                        >
                        </el-input>
                    </div>
                </el-form-item>
            </el-form>
            <h5
                v-if="Object.keys(userData).length > 0"
                class="text-xl text-center"
            >
                開單會員：<strong>{{ userData.name }}</strong>
            </h5>
            <el-form
                v-if="Object.keys(userData).length > 0"
                ref="formRefDom"
                class="w-full"
                :rules="rules"
                :model="form"
            >
                <div>
                    <div class="p-2 px-10 bg-white md:rounded-lg">
                        <el-form-item prop="name">
                            <div class="flex mb-2">
                                <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
                                <h5 class="text-base font-bold">活動名稱</h5>
                            </div>
                            <el-input
                                id="form.id"
                                v-model="form.name"
                                maxlength="10"
                                placeholder="EX : 吃飯、唱歌、小酌、電影、聊天、戶外活動..."
                                show-word-limit
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="p-2 px-10 mt-1 bg-white md:rounded-lg">
                        <div class="mb-2">
                            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
                            <h5 class="text-base font-bold">活動時間</h5>
                            <div>
                                <el-radio-group
                                    v-model="currentRightNowActivityType"
                                    @change="changeRightNowActivityType"
                                >
                                    <el-radio
                                        v-for="(
                                            type, key
                                        ) in rightNowActivityTypes"
                                        :key="key"
                                        :label="key"
                                        size="large"
                                        >{{ type }}</el-radio
                                    >
                                </el-radio-group>
                            </div>
                        </div>
                        <div
                            v-if="currentRightNowActivityType === 'type1'"
                            class="my-2 text-sm text-gray-300"
                        >
                            招募時間：60 分鐘
                        </div>
                        <div v-else>
                            <label for="form.started_at"
                                >活動開始時間
                                <span class="text-red-500">*</span></label
                            >
                            <div class="flex">
                                <el-form-item class="flex-1" prop="started_at">
                                    <el-date-picker
                                        id="form.started_at"
                                        v-model="form.started_at"
                                        :editable="false"
                                        :picker-options="startedAtOptions"
                                        :clearable="false"
                                        type="date"
                                        placeholder="選擇活動日期"
                                        style="width: 100%"
                                        @change="
                                            $delete(form, 'started_at_time');
                                            $delete(form, 'due_at');
                                            $delete(form, 'due_at_time');
                                        "
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    v-if="form.started_at instanceof Date"
                                    class="relative flex-1 ml-2"
                                    prop="started_at_time"
                                >
                                    <el-time-select
                                        v-model="form.started_at_time"
                                        style="width: 100%"
                                        :editable="false"
                                        :clearable="false"
                                        placeholder="選擇活動時間"
                                        :picker-options="{
                                            start: canSelectStartTime,
                                            step: '01:00',
                                            end: '23:00',
                                        }"
                                        @change="
                                            $delete(form, 'due_at_time');
                                            $set(
                                                form,
                                                'due_at',
                                                form.started_at
                                            );
                                            $set(
                                                form,
                                                'due_at_time',
                                                form.started_at_time
                                            );
                                        "
                                    >
                                    </el-time-select>
                                    <p
                                        class="absolute top-0 h-[32px] flex items-center right-2 text-gray-300"
                                    >
                                        時間
                                    </p>
                                </el-form-item>
                            </div>
                            <div class="text-gray-400 text-xs mb-[22px]">
                                為預留報名時間，僅可建立{{
                                    waitHour - 1
                                }}小時後的即刻快閃活動
                            </div>
                        </div>
                        <div
                            v-if="
                                form.started_at instanceof Date &&
                                form.started_at_time
                            "
                        >
                            <label for="form.due_at"
                                >招募截止時間
                                <span class="text-red-500">*</span></label
                            >
                            <div class="flex">
                                <el-form-item class="flex-1" prop="due_at">
                                    <el-date-picker
                                        id="form.recruit_date"
                                        v-model="form.due_at"
                                        :clearable="false"
                                        :editable="false"
                                        type="date"
                                        placeholder="選擇截止日期"
                                        style="width: 100%"
                                        :picker-options="dueAtOptions"
                                        @change="$delete(form, 'due_at_time')"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                {{/** 招募時間 */}}
                                <DueTimeSelect
                                    v-if="
                                        form.due_at instanceof Date &&
                                        form.started_at_time !== undefined
                                    "
                                    :form="form"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="p-2 px-10 mt-1 bg-white md:rounded-lg">
                        <el-form-item prop="duration" class="relative">
                            <div class="flex mb-2">
                                <h5 class="text-base font-bold">活動時長</h5>
                            </div>
                            <div class="flex items-center">
                                <button
                                    :disabled="1 >= form.duration"
                                    class="flex items-center justify-center w-5 h-5 border border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                                    @click.prevent="chagneTime(false)"
                                >
                                    -
                                </button>
                                <input
                                    v-model.number="form.duration"
                                    type="number"
                                    class="text-center w-[80px] mx-5"
                                    @input="inputTime"
                                    @blur="inputBlurTime"
                                />
                                <button
                                    :disabled="24 <= form.duration"
                                    class="flex items-center justify-center w-5 h-5 text-red-500 border border-red-500 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                                    @click.prevent="chagneTime(true)"
                                >
                                    +
                                </button>
                            </div>
                            <ActivityChooseHourOrDayTime
                                :unit.sync="form.unit"
                            />
                        </el-form-item>
                    </div>
                    <div class="p-2 px-10 mt-1 bg-white md:rounded-lg">
                        <el-form-item prop="provider_required" class="relative">
                            <div class="flex mb-2">
                                <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
                                <h5 class="text-base font-bold">
                                    活動需求人數
                                </h5>
                            </div>
                            <div class="flex items-center">
                                <button
                                    :disabled="1 >= form.provider_required"
                                    class="flex items-center justify-center w-5 h-5 border border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                                    @click.prevent="chagneRequiredCount(false)"
                                >
                                    -
                                </button>
                                <input
                                    v-model.number="form.provider_required"
                                    type="number"
                                    class="text-center w-[80px] mx-5"
                                    @input="inputRequiredCount"
                                    @blur="inputBlurRequiredCount"
                                />
                                <button
                                    :disabled="10 <= form.provider_required"
                                    class="flex items-center justify-center w-5 h-5 text-red-500 border border-red-500 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                                    @click.prevent="chagneRequiredCount(true)"
                                >
                                    +
                                </button>
                            </div>
                            <!-- <el-select v-model.number="form.provider_required"
                                               class="w-full">
                                        <el-option v-for="item in 10"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item"></el-option>
                                    </el-select> -->
                            <p
                                class="absolute bottom-0 h-[32px] flex items-center right-10 text-gray-300"
                            >
                                人
                            </p>
                        </el-form-item>
                    </div>

                    <div class="p-2 px-10 mt-1 bg-white md:rounded-lg">
                        <el-form-item prop="hourly_pay" class="relative">
                            <div class="flex mb-2">
                                <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
                                <h5
                                    v-if="form.unit === 'hour'"
                                    class="text-base font-bold"
                                >
                                    每小時活動出席費
                                </h5>
                                <h5 v-else class="text-base font-bold">
                                    每天活動出席費
                                </h5>
                            </div>
                            <el-input
                                v-if="form.hourly_pay !== 0"
                                id="form.hourly_pay"
                                v-model.number="form.hourly_pay"
                                type="number"
                                @input="handlePrice('hourly_pay', maxHourPrice)"
                                @change="
                                    handlePrice('hourly_pay', maxHourPrice)
                                "
                            ></el-input>
                            <p
                                v-if="form.hourly_pay !== 0"
                                class="absolute bottom-0 h-[32px] flex items-center right-2 text-gray-300"
                            >
                                / {{ form.unit === "hour" ? "時數" : "天數" }}
                            </p>
                            <div
                                v-if="form.hourly_pay === 0"
                                class="border border-gray-200 rounded-md py-0.5 px-2"
                            >
                                服務商報價
                            </div>
                        </el-form-item>
                        <!-- 服務商報價每小時或每天單價選擇 -->
                        <ActivityProviderCustomHourPrice
                            :hourPrice.sync="form.hourly_pay"
                            :unit="form.unit"
                            class="px-5 mb-[22px]"
                        />
                    </div>

                    <div class="p-2 px-10 mt-1 bg-white md:rounded-lg">
                        <div class="flex mb-2">
                            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
                            <h5 class="text-base font-bold">活動地點</h5>
                        </div>
                        <div class="flex">
                            <el-form-item prop="location" class="flex-grow">
                                <label for=""
                                    >會面城市與地點（建議選擇公開場合）<span
                                        class="text-red-500"
                                        >*</span
                                    ></label
                                >
                                <GoogleMapAutoComplete
                                    class="w-full"
                                    :textareaClass="'mt-[2px]'"
                                    :form.sync="form"
                                    :showCommentText="false"
                                    placement="bottom"
                                />
                                <div
                                    v-if="$isEmpty(form.location)"
                                    class="text-sm text-red-500"
                                >
                                    即刻快閃活動地點目前僅限定大台北都會區
                                </div>
                                <span
                                    slot="error"
                                    slot-scope="scope"
                                    class="el-form-item__error"
                                    style="display: inline-block"
                                >
                                    {{ scope.error }}
                                </span>
                                <el-form-item
                                    v-if="!$isEmpty(form.location)"
                                    prop="district"
                                    class="flex-grow"
                                >
                                    <span
                                        v-show="!$isEmpty(form.location)"
                                        slot="error"
                                        slot-scope="scope"
                                        class="-mt-2 el-form-item__error"
                                        style="display: inline-block"
                                    >
                                        {{ scope.error }}
                                    </span>
                                </el-form-item>
                            </el-form-item>
                        </div>
                        <el-form-item prop="requirement" class="mt-5">
                            <!-- 特殊需求 -->
                            <ActivitySpecialNote
                                :specialNote.sync="form.requirement"
                            />
                        </el-form-item>
                        <el-form-item prop="description">
                            <label
                                >即刻快閃活動內容
                                <span class="text-red-500">*</span></label
                            >
                            <el-input
                                v-model="form.description"
                                placeholder="請詳細說明活動內容，快閃服務商會依據此內容報名您的活動，活動不可違反法律及社會風俗"
                                type="textarea"
                                rows="5"
                                @keypress.native.capture="onKeypressOrPaste"
                                @paste.native.capture="onKeypressOrPaste"
                            >
                            </el-input>
                            <div class="text-right">
                                <span
                                    v-if="!$isEmpty(form.description)"
                                    :class="
                                        form.description.length >
                                        activityNoteLimit
                                            ? 'text-orange-600'
                                            : ''
                                    "
                                    >{{ form.description.length }}/{{
                                        activityNoteLimit
                                    }}</span
                                >
                            </div>
                        </el-form-item>
                        <el-checkbox
                            v-model="form.anonymous"
                            :true-label="1"
                            :false-label="0"
                            >隱藏個人資訊</el-checkbox
                        >
                    </div>
                </div>
            </el-form>
            <div
                v-if="Object.keys(userData).length > 0"
                class="p-5 px-10 mt-1 bg-white md:rounded-lg"
            >
                <div class="flex items-center justify-end">
                    <span
                        v-if="form.hourly_pay"
                        class="flex-1 text-xl font-bold text-red-500 OpenSansFont"
                        >$ {{ total }}</span
                    >
                    <button
                        class="ml-2 max-w-[130px] flex-grow cursor-pointer rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed disabled:bg-gray-300 md:text-base"
                        @click.prevent="onSubmit('formRefDom')"
                    >
                        下一步
                    </button>
                </div>
            </div>
        </div>
        {{/** 活動詳細資料 */}}
        <ConfirmActivity
            v-if="showConfirmDetail"
            :userData="userData"
            :form="form"
            :showConfirmDetail.sync="showConfirmDetail"
            @onCreated="closeDialog"
        />
    </el-dialog>
</template>

<script>
// 導入 三位數加上逗號演算法
import { formatCurrency } from "@/service/anyService";
import moment from "moment";
// 使用語系檔取得地區資料
import { areas } from "@/langs/tw.json";
// 導入 google map autocomplete 組件
import GoogleMapAutoComplete from "@/components/GoogleMapAutoComplete.vue";
// 服務商報價時 顯示可選擇金額
import ActivityProviderCustomHourPrice from "./components/ActivityProviderCustomHourPrice.vue";
// 即刻快閃單 特殊需求
import ActivitySpecialNote from "./components/ActivitySpecialNote.vue";

import ConfirmActivity from "./components/ConfirmActivity.vue";
import DueTimeSelect from "./components/DueTimeSelect.vue";
// 活動計價以小時或以天數計價 ui 切換
import ActivityChooseHourOrDayTime from "./components/ActivityChooseHourOrDayTime.vue";
import { isEmpty } from "@/service/anyService";

export default {
    components: {
        GoogleMapAutoComplete,
        ActivityProviderCustomHourPrice,
        ActivitySpecialNote,
        ConfirmActivity,
        DueTimeSelect,
        ActivityChooseHourOrDayTime,
    },
    props: {
        // 顯示彈窗
        propsShowDialog: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        // 可選的活動開始時間
        canSelectStartTime() {
            // 選擇活動日期時間轉換成 unix time
            const activityDay = moment(this.form.started_at).valueOf();
            // 當下時間 unix time
            let today = moment()
                .add(2 - 1, "hours")
                .format("YYYY-MM-DD HH:mm:ss");
            today = moment(today).valueOf();
            // 當下時間 往後 process.env.ACTIVITY_WAIT_TIME 小時選擇時間
            const addHour = moment().add(1, "hours").hours();
            // 當選擇日期大於今日時 可以從 00:00開始選擇
            if (activityDay > today) {
                return "00:00";
            }
            // 當下時間往後 小時 process.env.ACTIVITY_WAIT_TIME 數字小於10 時 要補零
            if (addHour < 10) {
                return `0${addHour}:00`;
            }
            return `${addHour}:00`;
        },
        // 訂單總額
        total() {
            return (
                parseInt(this.form.hourly_pay) *
                parseInt(this.form.duration) *
                parseInt(this.form.provider_required)
            );
        },
    },
    data() {
        return {
            userData: {},
            showDialog: false,

            loading: false,
            disabled: false,

            // 即刻快閃預設 type
            currentRightNowActivityType: "type1",

            // 選擇即刻快閃模式
            rightNowActivityTypes: {
                type1: "現在",
                type2: "指定時間",
            },

            userForm: {
                countryCode: "886",
                mobile: "",
            },
            // 表單資料
            form: {
                description: "",
                started_at_time: "",
                duration: 2,
                provider_required: 1,
                hourly_pay: 2000,
                anonymous: 0,
                unit: "hour",
            },
            userFormRules: {
                mobile: [
                    {
                        required: true,
                        message: "輸入搜尋手機號碼",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `手機號碼必須為數字`,
                        trigger: "blur",
                    },
                    { validator: this.formPhoneSearchUser, trigger: "blur" },
                ],
            },
            // 最高每小時活動出席費
            maxHourPrice: process.env.VUE_APP_POINT_MAX_LIMIT,
            // 即刻快閃緩衝時間
            waitHour: process.env.VUE_APP_ACTIVITY_WAIT_TIME,
            // 活動可選日期
            startedAtOptions: {
                disabledDate: (time) => {
                    // 判斷時間小於當下時間時  以及超過 30天時間為 disabled 狀態
                    return (
                        time.getTime() < moment().add(-1, "days") ||
                        time.getTime() > moment().add(29, "days")
                    );
                },
            },
            // 招募時間可選日期
            dueAtOptions: {
                disabledDate: (time) => {
                    // 判斷是否跨日問題
                    if (this.form.started_at_time === "00:00") {
                        return (
                            time.getTime() <
                                moment()
                                    .add(1, "hours")
                                    .add(-1, "days")
                                    .valueOf() ||
                            time.getTime() >
                                moment(this.form.started_at)
                                    .add(-1, "days")
                                    .valueOf()
                        );
                    }
                    return (
                        time.getTime() <
                            moment()
                                .add(1, "hours")
                                .add(-1, "days")
                                .valueOf() ||
                        time.getTime() > moment(this.form.started_at).valueOf()
                    );
                },
            },

            // 表單規則
            rules: {
                name: [
                    {
                        required: true,
                        message: "活動名稱為必填",
                        trigger: "change",
                    },
                    {
                        max: 10,
                        message: "活動名稱最多為10個字",
                        trigger: "blur",
                    },
                ],
                provider_required: [
                    {
                        required: true,
                        message: "活動需求人數為必填",
                        trigger: "blur",
                    },
                ],
                due_at: [
                    {
                        required: true,
                        message: "招募截止日期為必填",
                        trigger: "blur",
                    },
                ],
                due_at_time: [
                    {
                        required: true,
                        message: "招募截止時間為必填",
                        trigger: "blur",
                    },
                ],
                started_at: [
                    {
                        required: true,
                        message: "活動開始日期為必填",
                        trigger: "blur",
                    },
                ],
                started_at_time: [
                    {
                        required: true,
                        message: "活動開始時間為必填",
                        trigger: "blur",
                    },
                ],
                duration: [
                    {
                        required: true,
                        message: "請選擇活動時數",
                        trigger: "blur",
                    },
                ],
                district: [
                    // { required: true, message: "活動地區為必填", trigger: "blur" },
                    { validator: this.formCheckInTaipei, trigger: "blur" },
                ],
                location: [
                    {
                        required: true,
                        message: "活動地點為必填",
                        trigger: ["blur"],
                    },
                    {
                        min: 1,
                        max: 200,
                        message: "活動地點最多只能200個字",
                        trigger: "blur",
                    },
                ],
                hourly_pay: [
                    {
                        required: true,
                        message: "每小時活動出席費為必填",
                        trigger: "blur",
                    },
                    { validator: this.formPriceCheck, trigger: "blur" },
                ],
                description: [
                    {
                        required: true,
                        message: "活動內容為必填",
                        trigger: "blur",
                    },
                    { max: 500, message: "活動內容為500字內", trigger: "blur" },
                ],
            },
            // 活動備註字數限制
            activityNoteLimit: parseInt(process.env.VUE_APP_INPUT_MIN_LIMIT),
            // 判斷是否顯示 活動表單詳情頁
            showConfirmDetail: false,
            areas: areas,
        };
    },
    methods: {
        // 輸入中檢查
        onInput(val) {
            // 當手機號碼欄位為空
            if (this.$isEmpty(val)) {
                this.disabled = true;
            }
            // 當手機號碼欄位非數字時
            if (!val.match(/^[0-9]*$/)) {
                this.disabled = true;
            }
        },

        // 使用手機號碼搜尋供給者
        async formPhoneSearchUser(rule, value, callback) {
            // 判斷信箱是否為空值 或 信箱格式是否正確
            if (value !== "") {
                if (value.length > 0 && value[0] == 0) {
                    this.userForm.mobile = value.substring(1);
                }
                this.loading = true;
                try {
                    const { data } = await this.$api.UserSearchByPhone(
                        this.userForm.countryCode + this.userForm.mobile
                    );
                    // 判斷身份是否為服務商 （服務商身份 role = 1或2)
                    if (data.role > 0) {
                        // 取消旋轉動畫
                        this.loading = false;
                        return callback(new Error("該號碼已是服務商"));
                    }
                    // 取消旋轉動畫
                    this.loading = false;
                    this.userData = data;
                    return callback();
                } catch (err) {
                    console.log("err =>", err);
                    this.userData = {};
                    // 取消旋轉動畫
                    this.loading = false;
                    return callback(new Error("找不到用戶"));
                }
            } else {
                // 取消旋轉動畫
                this.loading = false;
                return callback(new Error("輸入搜尋手機號碼"));
            }
        },
        // 當輸入文字時或複製貼上時 觸發事件 當輸入文字超過 字數限制 則不能輸入或貼上
        onKeypressOrPaste(e) {
            // 判斷是否大於目前限定最大字數
            if (this.form.description.length >= this.activityNoteLimit) {
                // 當超過字數時 不給輸入
                e.preventDefault();
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // 判斷有使用者資料才顯示訂單詳情
                if (Object.keys(this.userData).length > 0) {
                    this.showConfirmDetail = true;
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "資訊未填寫正確",
                });
            }
        },

        /**
         * 每小時出席費超過最高額度時觸發
         * @param { type Strin or Number(字串或數字) } key 服務項目分類 key
         * @param { type Number(數字) } maxPrice
         */
        handlePrice(key, maxPrice) {
            let price = this.form[key];
            // 判斷超過儲值最大上下額度時直接給予最大上限額度
            if (price > maxPrice) {
                this.$set(this.form, key, maxPrice);
                return;
            }
            this.$set(this.form, key, price);
        },

        // 判斷價格是否小於 2000 或大於 最高金額 999999
        formPriceCheck(rule, value, callback) {
            if (value === 0) {
                return callback();
            }
            // 判斷數字是否小於最小儲值額度限制
            if (
                value <
                (this.form.unit === "hour"
                    ? parseInt(process.env.VUE_APP_ACTIVITY_MIN_PRICE)
                    : parseInt(process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY))
            ) {
                return callback(
                    new Error(
                        `請輸入大於 ${formatCurrency(
                            this.form.unit === "hour"
                                ? parseInt(
                                      process.env.VUE_APP_ACTIVITY_MIN_PRICE
                                  )
                                : parseInt(
                                      process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY
                                  )
                        )} 以上的數值`
                    )
                );
            }
            // 判斷數字是否大於最大儲值額度限制
            if (value > process.env.VUE_APP_POINT_MAX_LIMIT) {
                return callback(
                    new Error(
                        `請輸小於 ${formatCurrency(
                            process.env.VUE_APP_POINT_MAX_LIMIT
                        )} 以上的數值`
                    )
                );
            }
            return callback();
        },

        /**
         * 判斷只有台北地區能開立即刻快閃活動訂單
         * 2022-10-06 開會結果 只能給活動地點在台北的地區開立即刻快閃活動地單
         */
        formCheckInTaipei(rule, value, callback) {
            const canApplyAreas = JSON.parse(process.env.VUE_APP_DEMAND_AREA);
            if (!canApplyAreas.includes(value)) {
                const errorString = `活動只限制大台北都會區 ${canApplyAreas
                    .map((item) => this.areas[item].name)
                    .join("、")} 可舉辦`;
                return callback(new Error(errorString));
            }
            return callback();
        },

        /**
         * 預訂時數新增或減少
         * @param { type Boolean(布林) } isAdd 判斷是否增加時常
         */
        chagneTime(isAdd = false) {
            if (isAdd) {
                // 點擊增加時增加時數
                this.$set(this.form, "duration", this.form.duration++);
            } else {
                // 判斷計價為每小時單價時觸發
                if (this.form.unit === "hour") {
                    // 點擊減少時減少時數 但不可小於每個分類活動的最少預訂時數
                    Number(process.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION) <
                    this.form.duration
                        ? this.$set(this.form, "duration", this.form.duration--)
                        : this.$set(this.form, "duration", this.form.duration);
                }
                // 判斷計價為以天計價時觸發
                if (this.form.unit === "day") {
                    // 點擊減少時減少時數 但不可小於每個分類活動的最少預訂天數
                    Number(
                        process.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION_DAY
                    ) < this.form.duration
                        ? this.$set(this.form, "duration", this.form.duration--)
                        : this.$set(this.form, "duration", this.form.duration);
                }
            }
        },
        // 預訂時數輸入
        inputTime(e) {
            if (
                e.data !== null &&
                (e.data === "-" ||
                    e.data === "+" ||
                    e.data === "e" ||
                    e.data === "E")
            ) {
                // 判斷計價為每小時單價時觸發
                if (this.form.unit === "hour") {
                    // 設定預訂最少時數值
                    this.$set(
                        this.form,
                        "duration",
                        Number(porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION)
                    );
                } else {
                    // 判斷計價為以天計價時觸發
                    this.$set(
                        this.form,
                        "duration",
                        Number(porcess.env.ACTIVITY_MIN_LIMIT_DURATION_DAY)
                    );
                }
            }
            // 判斷計價為每小時單價時觸發
            if (this.form.unit === "hour" && e.target.value.length > 0) {
                // 判斷小於預訂最少時數時觸發
                if (
                    e.target.value <
                    Number(porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION)
                ) {
                    // 設定預訂最少時數值
                    this.$set(
                        this.form,
                        "duration",
                        Number(porcess.env.ACTIVITY_MIN_LIMIT_DURATION)
                    );
                }
                // 判斷大於預訂最多時數時觸發
                if (
                    e.target.value >
                    Number(porcess.env.VUE_APP_ACTIVITY_MAX_LIMIT_DURATION)
                ) {
                    // 設定預訂最多時數
                    this.$set(
                        this.form,
                        "duration",
                        Number(porcess.env.VUE_APP_ACTIVITY_MAX_LIMIT_DURATION)
                    );
                }
            }
            // 判斷計價為以天計價時觸發
            if (this.form.unit === "day" && e.target.value.length > 0) {
                // 判斷小於預訂最少天數時觸發
                if (
                    e.target.value <
                    Number(porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION_DAY)
                ) {
                    // 設定預訂最少天數值
                    this.$set(
                        this.form,
                        "duration",
                        Number(
                            porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION_DAY
                        )
                    );
                }
                // 判斷大於預訂最大天數時觸發
                if (
                    e.target.value >
                    Number(porcess.env.VUE_APP_ACTIVITY_MAX_LIMIT_DURATION_DAY)
                ) {
                    // 設定預訂最大天數值
                    this.$set(
                        this.form,
                        "duration",
                        Number(
                            porcess.env.VUE_APP_ACTIVITY_MAX_LIMIT_DURATION_DAY
                        )
                    );
                }
            }
        },
        // 預訂時數離開輸入框時
        inputBlurTime(e) {
            // 判斷計價為每小時單價時觸發
            if (e.target.value.length <= 0 && this.form.unit === "hour") {
                // 設定預訂最少時數值
                this.$set(
                    this.form,
                    "duration",
                    Number(porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION)
                );
            } else {
                // 判斷計價為以天計價時觸發
                // 設定預訂最少天數值
                this.$set(
                    this.form,
                    "duration",
                    Number(porcess.env.VUE_APP_ACTIVITY_MIN_LIMIT_DURATION_DAY)
                );
            }
        },

        /**
         * 活動需求人數新增或減少
         * @param { type Boolean(布林) } isAdd 判斷是否增加時常
         */
        chagneRequiredCount(isAdd = false) {
            if (isAdd) {
                // 點擊增加時增加時數
                this.$set(
                    this.form,
                    "provider_required",
                    this.form.provider_required++
                );
            } else {
                // 點擊減少時減少時數 但不可小於每個分類活動的最少預訂時數
                1 < this.form.provider_required
                    ? this.$set(
                          this.form,
                          "provider_required",
                          this.form.provider_required--
                      )
                    : this.$set(
                          this.form,
                          "provider_required",
                          this.form.provider_required
                      );
            }
        },
        // 預訂時數輸入
        inputRequiredCount(e) {
            if (
                e.data !== null &&
                (e.data === "-" ||
                    e.data === "+" ||
                    e.data === "e" ||
                    e.data === "E")
            ) {
                this.$set(this.form, "provider_required", 1);
            }
            if (e.target.value.length > 0) {
                if (e.target.value < 1) {
                    this.$set(this.form, "provider_required", 1);
                }
                if (e.target.value > 10) {
                    this.$set(this.form, "provider_required", 10);
                }
            }
        },
        // 預訂時數離開輸入框時
        inputBlurRequiredCount(e) {
            if (e.target.value.length <= 0) {
                this.$set(this.form, "provider_required", 1);
            }
        },

        closeDialog() {
            this.showConfirmDetail = false;
            this.userData = {};
            this.$set(this.userForm, "mobile", "");
            this.$emit("onCloseDialog", false);
        },
        openDialog() {
            this.form = {
                description: "",
                started_at_time: "",
                duration: 2,
                provider_required: 1,
                hourly_pay: 2000,
                unit: "hour",
            };
        },

        /**
         * 選擇即刻快閃模式事件
         * @param type 即刻快閃 type
         */
        changeRightNowActivityType(type) {
            // 判斷選擇 ”現在“ 模式 即刻快閃時 須移除 開始時間 以及招募時間資訊
            if (type === "type1") {
                this.$set(this.form, "started_at", null);
                this.$set(this.form, "started_at_time", "");
                this.$set(this.form, "due_at", null);
                this.$set(this.form, "due_at_time", "");
            }
        },
    },
    watch: {
        // 新增點擊返回時 將瀏覽器視窗置頂機制
        showConfirmDetail(val) {
            if (val === false) {
                window.scrollTo(0, 0);
            }
        },
        propsShowDialog(val) {
            this.showDialog = val;
        },
        "form.unit": (val) => {
            // 判斷是以天計價時 最少金額須為 VUE_APP_ACTIVITY_MIN_PRICE_DAY 便數值
            if (val === "day") {
                if (
                    this.form.hourly_pay <
                        Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY) &&
                    this.form.hourly_pay !== 0
                ) {
                    this.$set(
                        this.form,
                        "hourly_pay",
                        Number(process.env.VUE_APP_ACTIVITY_MIN_PRICE_DAY)
                    );
                }
            }
        },
    },
};
</script>
