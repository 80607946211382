<template>
    <main>
        <ActivityDetail :order="form" />
        <div class="p-5 px-10 mt-1 text-base bg-white md:rounded-lg">
            <div class="flex">
                <h5 class="text-base font-bold">其它</h5>
            </div>
            <div class="flex items-center mt-2 text-gray-400">
                <div class="flex-1">
                    <span>使用快閃折抵金</span>
                    (<span>餘額 ${{ voucher | formatCurrency }}</span
                    >)
                    <el-popover placement="bottom" width="200" trigger="click">
                        <template #reference>
                            <span class="text-base cursor-pointer"
                                ><i class="ml-1 el-icon-warning-outline"></i
                            ></span>
                        </template>
                        <div>
                            快閃折抵金使用說明：<br />
                            1. 訂單滿 $1,000 即可折抵 $100，折抵金額以 $1,000
                            為上限。<br />
                            2.
                            使用快閃折抵金之訂單若取消，則快閃折抵金將全數退還至您的帳戶中。
                        </div>
                    </el-popover>
                </div>
                <el-switch
                    id="pay_voucher"
                    v-model="isPayUseVoucher"
                    :disabled="voucher == 0"
                    style="display: block"
                    active-color="#ff4949"
                    inactive-color="#707070"
                >
                </el-switch>
            </div>
            <div class="flex items-end mt-2">
                <label class="flex-1">使用現金付款</label>
                <el-switch
                    v-model="isCashPay"
                    active-color="#ff4949"
                    inactive-color="#707070"
                >
                </el-switch>
            </div>
        </div>
        <ActivityBilling
            :form="form"
            :userData="userData"
            :usePayVoucher="isPayUseVoucher ? 1 : 0"
        />

        <div
            class="p-5 px-10 bg-white md:flex md:items-center md:justify-center"
        >
            <button
                v-loading="loading"
                :disabled="loading"
                class="mr-2 md:max-w-[130px] w-full md:flex-grow cursor-pointer md:ml-5 md:mb-0 mb-2 md:mt-0 mt-3 rounded border border-black px-3 py-2 text-sm text-black md:text-base disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-300 disabled:text-white"
                @click.prevent="$emit('update:showConfirmDetail', false)"
            >
                上一步
            </button>
            <button
                v-if="!isCashPay"
                v-loading="loading"
                :disabled="loading || totalPrice > wallet"
                class="md:max-w-[130px] w-full md:flex-grow md:ml-2 flex-1 cursor-pointer border border-red-500 rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed myDisabled md:text-base"
                @click.prevent="onSubmit"
            >
                發起活動
            </button>
            <button
                v-else
                v-loading="loading"
                :disabled="loading"
                class="md:max-w-[130px] w-full md:flex-grow md:ml-2 flex-1 cursor-pointer border border-red-500 rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed myDisabled md:text-base"
                @click.prevent="onSubmit"
            >
                發起活動
            </button>
        </div>
        <div
            v-if="totalPrice > wallet"
            class="flex justify-center font-bold text-red-500 OpenSansFont"
        >
            餘額不足：${{ (totalPrice - wallet) | formatCurrency }}
        </div>
    </main>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import moment from "moment";
import { isEmpty } from "@/service/anyService";
import ActivityDetail from "./ActivityDetail.vue";
import ActivityBilling from "./ActivityBilling.vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import store from "@/store";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.props = {
  form: {
    type: Object,
    required: true
  },
  showConfirmDetail: {
    type: Boolean
  },
  userData: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const langDatas = ref(store.state.i18nStore.langDatas);
  const errorCallback = async err => {
    await store.dispatch("apiStore/errorCallback", err);
  };
  const getLoginUserInfo = async () => {
    await store.dispatch("userStore/getLoginUserInfo");
  };
  const route = useRoute();
  const router = useRouter();
  // 判斷是否使用快閃折抵金
  const isPayUseVoucher = ref(false);
  let loading = ref(false);
  const props = __props;
  // 判斷是否選擇現金付款
  const isCashPay = ref(false);
  // 單一訂單費用合計
  const singleOrderTotal = computed(() => {
    return parseInt(props.form.hourly_pay) * parseInt(props.form.duration);
  });
  // 單一平台服務費
  const singleOrderFee = computed(() => {
    return Math.ceil(parseInt(props.form.hourly_pay) * parseInt(props.form.duration) * process.env.VUE_APP_PANEL_FEE);
  });
  // 全部平台服務費
  const countOrderFee = computed(() => {
    return singleOrderFee.value * parseInt(props.form.provider_required);
  });
  // 費用合計
  const total = computed(() => {
    return parseInt(props.form.hourly_pay) * parseInt(props.form.duration) * parseInt(props.form.provider_required);
  });
  // 儲值餘額
  const wallet = computed(() => {
    // 判斷沒有 wallet key 給予預設值 0
    if (isEmpty(props.userData.wallet)) {
      return 0;
    } else {
      // 儲值餘額
      return props.userData.wallet.balance;
    }
  });
  // 快閃折抵金餘額
  const voucher = computed(() => {
    // 判斷沒有 wallet key 給予預設值 0
    if (isEmpty(props.userData.wallet)) {
      return 0;
    } else {
      // 快閃折抵金餘額
      return props.userData.wallet.voucher;
    }
  });
  // 使用快閃折抵金折抵金額
  const voucherUsePoint = computed(() => {
    if (isPayUseVoucher.value && voucher.value > 0) {
      if (total.value >= 1000) {
        const discount = Math.floor(total.value / 1000);
        const result = discount * 100 > voucher.value ? voucher.value : discount * 100;
        if (result > 1000) {
          return 1000;
        } else {
          return result;
        }
      }
    }
    return 0;
  });
  // 總金額
  const totalPrice = computed(() => {
    return total.value + countOrderFee.value - voucherUsePoint.value;
  });

  // 折抵金折抵金額
  const discountPoint = computed(() => {
    const discount = parseInt(singleOrderTotal.value / 1000) * 100;
    let result = discount;
    result = result > 1000 ? 1000 : result;
    result = result * props.form.provider_required > voucher.value ? voucher.value : result * props.form.provider_required;
    return result;
  });

  // 設定 emit 事件名稱
  const emit = __ctx.emit;
  async function onSubmit() {
    let form = props.form;
    // 判斷是否使用折抵金
    form.pay_voucher = isPayUseVoucher.value ? 1 : 0;
    if (form.started_at instanceof Date) {
      // 將活動開始時間整理成後端要求格式
      form.started_at = moment(props.form.started_at).format("YYYY-MM-DD") + " " + props.form.started_at_time;
      // 將招募截止時間整理成後端要求格式
      form.due_at = moment(props.form.due_at).format("YYYY-MM-DD") + " " + props.form.due_at_time;
    } else {
      delete form.started_at;
      delete form.started_at_time;
      delete form.due_at;
      delete form.due_at_time;
    }
    await createRightNowActivity(form);
  }
  /**
   * 有足夠錢時建立即刻快閃
   */
  async function createRightNowActivity(form) {
    loading.value = true;
    try {
      // 判斷是否為現金單
      if (isCashPay.value) {
        const {
          data
        } = await proxy.$api.CreateRightNowActivityByCashApi({
          ...form,
          user_id: props.userData.id
        });
      } else {
        const {
          data
        } = await proxy.$api.CreateRightNowActivityApi({
          ...form,
          user_id: props.userData.id
        });
      }
      proxy.$message({
        type: "success",
        message: `新增 ${form.name} 即刻快閃活動成功`
      });
      loading.value = false;
      emit("onCreated");
    } catch (err) {
      console.log(err);
      loading.value = false;
      errorCallback({
        err
      });
    }
  }

  // 新增點擊下一步時 將瀏覽器視窗置頂機制
  if (props.showConfirmDetail) {
    window.scrollTo(0, 0);
  }
  return {
    isPayUseVoucher,
    loading,
    isCashPay,
    wallet,
    voucher,
    totalPrice,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  ActivityDetail,
  ActivityBilling
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped lang="scss">
p {
    @apply text-gray-700;
}
h5 {
    @apply text-base;
}
</style>
<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    @apply text-gray-500;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    @apply bg-red-500 border-red-500 hidden;
}
::v-deep .el-checkbox__label {
    @apply text-gray-400 pl-0;
}
::v-deep .el-checkbox__input {
    @apply hidden;
}
</style>
