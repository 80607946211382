<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="flex items-center p-5 mb-3 bg-white rounded-lg shadow-lg">
            <div class="grid grid-cols-12 gap-4 w-[100%]">
                <div class="col-span-12">
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                活動編號
                                <el-input
                                    v-model="filterOpts.demand_id"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                狀態
                                <el-select
                                    v-model="filterOpts.status"
                                    size="small"
                                    class="w-full"
                                    clearable
                                >
                                    <el-option
                                        v-for="(
                                            status, index
                                        ) in flashOrderOptions"
                                        :key="index"
                                        :label="status.label"
                                        :value="status.value"
                                    >
                                        {{ status.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                活動名稱
                                <el-input
                                    v-model="filterOpts.name"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                會員暱稱
                                <el-input
                                    v-model="filterOpts.user_name"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                成立日期 範圍
                                <el-date-picker
                                    v-model="dateInputsCreate"
                                    style="height: 33px; width: 100%"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                開始日期 範圍
                                <el-date-picker
                                    v-model="dateInputsStart"
                                    style="height: 33px; width: 100%"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </label>
                        </div>
                        <div class="flex items-end flex-1">
                            <btn
                                color="border border-red-500 text-orange-600 duration-500 hover:bg-red-500 hover:text-white"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink mr-2"
                                @onClick="showDialog = !showDialog"
                                >新增即刻快閃</btn
                            >
                            <btn
                                color="border border-red-500 bg-red-500 text-white"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink mr-2"
                                @onClick="goSearch"
                            >
                                搜尋
                            </btn>
                            <btn
                                color="border border-black"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink"
                                @onClick="reset"
                            >
                                重置
                            </btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <TableHead
                    ref="TableHeadRefDom"
                    :labels="tableLabels"
                    @changeSort="changeSort"
                />
                <tbody>
                    <tr
                        v-for="i in datas"
                        :key="i.id"
                        class="text-center transition duration-200"
                    >
                        <td>
                            <div>{{ i.demand_id }}</div>
                            <div v-if="i.is_x" class="text-red-500">
                                CityBananaX
                            </div>
                            <div>來源：{{ i.source_application }}</div>
                        </td>
                        <td>{{ i.status | flashOrderStatus }}</td>
                        <td>{{ i.name }}</td>
                        <td>{{ i.user.name }}</td>
                        <td>${{ i.details.pointPaid | formatCurrency }}</td>
                        <td>
                            {{ moment(i.created_at).format("YY/MM/DD HH:mm") }}
                        </td>
                        <td>
                            {{
                                $isEmpty(i.started_at)
                                    ? "服務商到場"
                                    : moment(i.started_at).format(
                                          "YY/MM/DD HH:mm"
                                      )
                            }}
                        </td>
                        <td>{{ i.paid_by === 1 ? "現金" : "線上" }}</td>
                        <!-- <td>{{ i.category.name }}</td> -->
                        <td
                            v-permission="['update']"
                            class="cursor-pointer"
                            @click="goEdit(i.id)"
                        >
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange"
                />
            </div>
        </div>
        <CreateRightNowActivityOrder
            :propsShowDialog="showDialog"
            @onCloseDialog="closeDialog"
        />
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";

// table Head
import TableHead from "@/components/table/TableHead.vue";
// options
import orderComplaintConfig from "@/config/orderComplaintConfig";
import orderConfig from "@/config/orderConfig.js";
import "@/global/filters/filterComplaint";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 導入開即刻快閃單組件
import CreateRightNowActivityOrder from "./createOrder/Form.vue";
import moment from "moment";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const {
    proxy
  } = getCurrentInstance();
  // init search Filter options
  const defaultFilterOpts = {
    id: "",
    demand_id: "",
    user_name: "",
    name: "",
    created_at_start: "",
    created_at_end: "",
    started_at_start: "",
    started_at_end: "",
    type: "",
    status: "",
    sort_by: "",
    // created_at or started_at or gross_price
    sort_type: "" // asc or desc
  };

  // 統整 getlist api filters
  const filterData = computed(() => {
    const filter = {};
    for (const key in filterOpts.value) {
      if (filterOpts.value[key]) {
        filter[key] = filterOpts.value[key];
      }
    }
    const result = {
      ...filter,
      limit: paginationData.value.limit,
      page: currentPage.value
    };
    return result;
  });
  // 狀態
  const statusOption = computed(() => {
    const status = orderComplaintConfig.complaintStatus;
    const result = [{
      label: "全部",
      value: ""
    }];
    for (const key in status) {
      result.push({
        label: status[key],
        value: key
      });
    }
    return result;
  });
  // 訂單狀態 options
  const flashOrderOptions = computed(() => {
    const status = orderConfig.flashOrderStatus;
    const result = [{
      label: "全部",
      value: ""
    }];
    for (const key in status) {
      result.push({
        label: status[key],
        value: key
      });
    }
    return result;
  });
  const loading = ref(false);
  const datas = ref([]);
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  const currentPage = ref(1);
  const filterOpts = ref({
    ...defaultFilterOpts
  }); // 篩選options
  const dateInputsCreate = ref([]); // 時間範圍
  const dateInputsStart = ref([]); // 時間範圍
  // table header
  const tableLabels = ref([{
    label: "活動編號"
  }, {
    label: "狀態"
  }, {
    label: "活動名稱"
  }, {
    label: "會員暱稱"
  }, {
    label: "總額",
    isSort: true,
    val: "gross_price"
  }, {
    label: "成立時間",
    isSort: true,
    val: "created_at"
  }, {
    label: "開始時間",
    isSort: true,
    val: "started_at"
  }, {
    label: "付款類型"
  }, {
    label: "編輯"
  }]);
  const TableHeadRefDom = ref(null);
  const showDialog = ref(false);
  // 讀取 list api
  async function getList() {
    loading.value = true;
    const {
      status,
      data
    } = await proxy.$api.SearchDemandsDatingsApi(filterData.value)
    // .customerComplaintApi(this.id, this.filterData)
    .then(res => {
      return {
        data: res.data,
        status: res.status
      };
    }).catch(() => {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }).finally(() => {
      loading.value = false;
    });
    if (status === 200) {
      datas.value = [...data.data];
      paginationData.value = {
        limit: data.per_page,
        total: data.total
      };
    }
  }
  // 搜尋
  function goSearch() {
    currentPage.value = 1;
    getList();
  }
  // 改變排序
  function changeSort(val) {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    getList();
  }
  // 重置
  function reset() {
    dateInputsCreate.value = [];
    dateInputsStart.value = [];
    filterOpts.value = {
      ...defaultFilterOpts
    };
    if (TableHeadRefDom.value) {
      TableHeadRefDom.value.reset();
    }
    currentPage.value = 1;
    paginationData.value = {
      limit: 10,
      total: 0
    };
    getList();
  }
  // 切頁相關
  function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    getList();
  }
  function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    getList();
  }
  // 到訂單細節頁,將此頁資訊帶入
  function goEdit(id) {
    router.push({
      name: "rightnow_activity_detail",
      params: {
        id
      }
    });
  }
  // 關閉彈窗
  function closeDialog(val) {
    showDialog.value = val;
    getList();
  }
  watch(() => dateInputsCreate.value, val => {
    if (val === null || val.length === 0) {
      filterOpts.value.created_at_start = "";
      filterOpts.value.created_at_end = "";
      return;
    }
    filterOpts.value.created_at_start = val[0];
    filterOpts.value.created_at_end = val[1];
  });
  watch(() => dateInputsStart.value, val => {
    if (val === null || val.length === 0) {
      filterOpts.value.started_at_start = "";
      filterOpts.value.started_at_end = "";
      return;
    }
    filterOpts.value.started_at_start = val[0];
    filterOpts.value.started_at_end = val[1];
  });
  onMounted(() => {
    getList();
  });
  onActivated(() => {
    getList();
  });
  return {
    moment,
    flashOrderOptions,
    loading,
    datas,
    paginationData,
    currentPage,
    filterOpts,
    dateInputsCreate,
    dateInputsStart,
    tableLabels,
    TableHeadRefDom,
    showDialog,
    goSearch,
    changeSort,
    reset,
    pageSizeChange,
    currentPageChange,
    goEdit,
    closeDialog
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent,
  Btn,
  TableHead,
  MyPagination,
  CreateRightNowActivityOrder
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
