<template>
    <div
        class="absolute bottom-0 h-[32px] flex items-center right-10 text-gray-700"
    >
        <div class="flex p-1 text-xs bg-gray-200 rounded-md w-[150px]">
            <div
                :class="unit === 'hour' ? 'bg-white rounded-sm' : ''"
                class="cursor-pointer text-center min-w-[50px] flex-1"
                @click="changeUnit('hour')"
            >
                小時
            </div>
            <div
                :class="unit === 'day' ? 'bg-white rounded-sm' : ''"
                class="text-center cursor-pointer min-w-[50px] flex-1"
                @click="changeUnit('day')"
            >
                天
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  // 選擇時數或天數方式
  const unit = ref("hour");

  /**
   * 選擇開單計算時間方式
   * @param { type String(字串) } type 開單計算時間方式
   */
  function changeUnit(type) {
    unit.value = type;
  }
  watch(() => unit.value, val => {
    emit("update:unit", val);
  });
  return {
    unit,
    changeUnit
  };
};
export default __sfc_main;
</script>
