var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"px-10 py-2 bg-white md:rounded-b-lg",class:![0, 1].includes(_vm.order.my_enrolled_status) && !_vm.isProvider
            ? 'md:rounded-t-lg'
            : ''},[_vm._m(0),_c('div',{staticClass:"grid grid-cols-2 gap-0 md:gap-4"},[_c('div',{class:_vm.isProvider && _vm.order.my_enrolled_status !== 1
                    ? 'col-span-2'
                    : ''},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動名稱")]),_c('h5',[_vm._v(_vm._s(_vm.order.name))])]),(_vm.isProvider && _vm.order.my_enrolled_status === 1)?_c('div',[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("訂單編號")]),_c('h5',{staticClass:"text-orange-600 underline cursor-pointer",on:{"click":function($event){return _vm.openDrawer(_vm.order.my_enrolled_dating)}}},[_vm._v(" "+_vm._s(_vm.order.my_enrolled_dating)+" ")])]):_vm._e(),(_vm.order.status !== undefined)?_c('div',{class:_vm.isProvider ? 'mt-5' : ''},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動狀態")]),_c('h5',[_vm._v(_vm._s(_vm.statusText))])]):_vm._e(),(_vm.isProvider)?_c('div',{class:_vm.isProvider ? 'mt-5' : ''},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("報名狀態")]),_c('h5',[_vm._v(_vm._s(_vm.myStatusText))])]):_vm._e()]),_c('div',{staticClass:"grid grid-cols-2 gap-0 md:gap-4"},[_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動需求人數")]),_c('h5',[_vm._v(_vm._s(_vm.order.provider_required)+"人")])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("出席鐘點費")]),_c('h5',[_vm._v(" $"+_vm._s(_vm._f("formatCurrency")(_vm.order.hourly_pay))+" / "+_vm._s(_vm.order.unit === "hour" ? "小時" : "天數")+" ")])])]),_c('div',{staticClass:"grid grid-cols-2 gap-0 md:gap-4"},[_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動開始時間")]),(_vm.order.started_at instanceof Date === true)?_c('h5',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.order.started_at))+" "+_vm._s(_vm.order.started_at_time)+" ")]):_c('h5',[_vm._v("服務商到場")])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動結束時間")]),(
                    _vm.order.status === undefined &&
                    _vm.order.started_at instanceof Date === true
                )?_c('h5',[_vm._v(" "+_vm._s(_vm.$moment( _vm.$moment(_vm.order.started_at).format("YYYY-MM-DD ") + _vm.order.started_at_time ) .add(_vm.order.duration, "hours") .format("YYYY/MM/DD HH:mm"))+" ")]):(_vm.order.started_at instanceof Date === false)?_c('h5',[_vm._v(" 服務商到場後計算 ")]):_c('h5',[_vm._v(" "+_vm._s(_vm.$moment(_vm.order.started_at) .add(_vm.order.duration, "hours") .format("YYYY/MM/DD HH:mm"))+" ")])])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("招募截止時間")]),_c('h5',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.order.due_at))+" "+_vm._s(_vm.order.due_at_time))])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動地點")]),_c('h5',[_vm._v(" "+_vm._s(_vm.$checkLang(_vm.langDatas, "areas", _vm.order.district))+" | "+_vm._s(_vm.order.location)+" ")])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("活動詳細內容")]),_c('h5',{staticClass:"break-all"},[_vm._v(_vm._s(_vm.order.description))])]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-sm text-gray-400"},[_vm._v("隱藏會員資訊")]),_c('h5',{staticClass:"break-all"},[_vm._v(" "+_vm._s(_vm.order.anonymous === 1 ? "隱藏" : "非隱藏")+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex mb-2"},[_c('h5',{staticClass:"text-base font-bold"},[_vm._v("活動資訊")])])
}]

export { render, staticRenderFns }