<template>
    <div class="relative w-full">
        <ul
            v-if="locations.length > 0 && showPopover"
            class="p-2 border border-gray-100 rounded-lg shadow-2xl"
        >
            <li
                v-for="(item, index) in locations"
                :key="index"
                class="py-2 border-b border-gray-100 cursor-pointer"
                @click="onChangeLocation(item)"
            >
                {{ item.terms[3] !== undefined ? item.terms[3].value : "" }}
                {{ item.terms[2] !== undefined ? item.terms[2].value : "" }}
                {{ item.terms[1] !== undefined ? item.terms[1].value : "" }}
                {{ item.terms[0].value }}
            </li>
        </ul>
        <textarea
            v-model="inputLocation"
            :class="textareaClass"
            class="w-full px-3 mb-2 border border-gray-100 rounded el-textarea__inner"
            placeholder="請輸入詳細會面地點，準確地址或詳細地標"
            rows="3"
            @keydown="onKeydown"
            @input="getAutocompleteLocation(inputLocation)"
        >
        </textarea>

        <!-- <ul v-if="!showPopover && $isEmpty(form.location)">
            <li class="text-red-500 ">請輸入詳細會面地點，準確地址或詳細地標</li>
        </ul> -->
    </div>
</template>

<script>
import { mapActions } from "vuex";
// 使用語系檔取得地區資料
import { googleMapAreas } from "@/langs/tw.json";

export default {
    name: "GoogleMapAutoComplete",
    props: {
        // 判斷 popover 箭頭方向跟呈現位置
        placement: {
            type: String,
            default: "bottom",
        },
        popoverClass: {
            type: String,
            default: "pb-5 w-full",
        },
        form: {
            type: Object,
            default() {
                return {
                    location: "",
                    district: "",
                };
            },
        },
        // 輸入匡樣式
        textareaClass: {
            type: String,
            default: "",
        },
        bookingDistrict: {
            type: String,
        },
        bookingLocation: {
            type: String,
        },
        // 顯示註解文字
        showCommentText: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            // 顯示 popover 彈窗
            showPopover: false,
            // 會面地點
            locations: [],
            // 輸入會面地點
            inputLocation: "",
            // 選中的地點字數
            selectLocationLength: 0,
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        // 主要用來檢查是否有小於 google api 給予得地點字數
        onKeydown(e) {
            /**
             * 只判斷刪除鍵時才觸發
             */
            if (e.keyCode == 8 || e.keyCode == 46) {
                /**
                 * 判斷目前輸入框字數
                 */
                console.log(
                    this.inputLocation.length <= this.selectLocationLength,
                    this.inputLocation.length,
                    this.selectLocationLength,
                    "this.inputLocation.length <= this.selectLocationLength"
                );
                if (this.inputLocation.length <= this.selectLocationLength) {
                    // 會面地點
                    this.$set(this.form, "location", "");
                    // 會面縣市
                    this.$set(this.form, "district", "");
                    // 更新父組件會面地點
                    this.$emit("update:bookingLocation", "");
                    // 更新父組件會面區域
                    this.$emit("update:bookingDistrict", "");
                }
            }
        },
        /**
         * 更換地點
         * @param { type String(字串) } location
         */
        async onChangeLocation(location) {
            // 取得地點位置陣列中 value 值並且反轉陣列後移除陣列中第一個選項且將陣列字串組合
            const parseLocation = location.terms
                .map((item) => item.value)
                .reverse()
                .slice(1)
                .join("");
            // 取得反轉陣列後 index 1 值為 縣市區域（ex: 台北市｜台中市 等等)
            const district = location.terms
                .map((item) => item.value)
                .reverse()[1];
            // 會面地點
            this.$set(this.form, "location", parseLocation);
            // 會面縣市
            this.$set(this.form, "district", googleMapAreas[district].key);
            this.inputLocation = parseLocation;
            // 會面地點資料傳送給父組件
            this.$emit("form:update", this.form);
            // 記住選中地點字數
            this.selectLocationLength = this.inputLocation.length;
            setTimeout(() => {
                // 清空會面地點
                this.locations = [];
            }, 1000);
            // 關閉 popover 彈窗
            this.showPopover = false;
        },
        /**
         * 取得自訂顯示地點
         * @param { type String(字串) } location
         */
        async getAutocompleteLocation(location) {
            const sleep = (milliseconds) => {
                return new Promise((resolve) =>
                    setTimeout(resolve, milliseconds)
                );
            };
            // 等待兩秒後才執行
            await sleep(500);
            // 判斷地點為空時不執行
            if (this.$isEmpty(location)) {
                return;
            }
            // 判斷傳送值 與 表單變數值 是否相同字數 不相同字數不往下執行 以防每打一個字就會執行 api 多餘的請求
            if (location.length !== this.inputLocation.length) {
                return;
            }
            if (!this.$isEmpty(this.form.location)) {
                console.log(this.form.location);
                this.$set(this.form, "location", location);
                return;
            }
            try {
                const { data } = await this.$api.AutocompleteLocationApi(
                    location
                );
                // 會面地點
                this.locations = data.predictions;
                // 開啟 popover 彈窗
                this.showPopover = true;
                return;
            } catch (err) {
                this.errorCallback({ err });
            }
        },
    },
    watch: {
        // 當地點資訊有變動時 要變更選取到的字串長度值
        inputLocation(val) {
            if (val !== undefined) {
                this.selectLocationLength = val.length;
            }
        },
        form(val) {
            if (!this.$isEmpty(val.location)) {
                this.inputLocation = val.location;
                this.selectLocationLength = val.location.length;
            }
        },
    },
    created() {
        this.inputLocation = this.$isEmpty(this.form.location)
            ? this.bookingLocation
            : this.form.location;
    },
};
</script>

<style lang="scss" scoped>
:focus:not(:focus-visible) {
    outline: 0;
}
</style>
