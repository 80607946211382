<template>
    <section
        class="px-10 py-2 bg-white md:rounded-b-lg"
        :class="
            ![0, 1].includes(order.my_enrolled_status) && !isProvider
                ? 'md:rounded-t-lg'
                : ''
        "
    >
        <div class="flex mb-2">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <h5 class="text-base font-bold">活動資訊</h5>
        </div>
        <div class="grid grid-cols-2 gap-0 md:gap-4">
            <div
                :class="
                    isProvider && order.my_enrolled_status !== 1
                        ? 'col-span-2'
                        : ''
                "
            >
                <p class="text-sm text-gray-400">活動名稱</p>
                <h5>{{ order.name }}</h5>
            </div>
            <div v-if="isProvider && order.my_enrolled_status === 1">
                <p class="text-sm text-gray-400">訂單編號</p>
                <h5
                    class="text-orange-600 underline cursor-pointer"
                    @click="openDrawer(order.my_enrolled_dating)"
                >
                    {{ order.my_enrolled_dating }}
                </h5>
            </div>
            <div
                v-if="order.status !== undefined"
                :class="isProvider ? 'mt-5' : ''"
            >
                <p class="text-sm text-gray-400">活動狀態</p>
                <h5>{{ statusText }}</h5>
            </div>
            <div v-if="isProvider" :class="isProvider ? 'mt-5' : ''">
                <p class="text-sm text-gray-400">報名狀態</p>
                <h5>{{ myStatusText }}</h5>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-0 md:gap-4">
            <div class="mt-5">
                <p class="text-sm text-gray-400">活動需求人數</p>
                <h5>{{ order.provider_required }}人</h5>
            </div>
            <div class="mt-5">
                <p class="text-sm text-gray-400">出席鐘點費</p>
                <h5>
                    ${{ order.hourly_pay | formatCurrency }} /
                    {{ order.unit === "hour" ? "小時" : "天數" }}
                </h5>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-0 md:gap-4">
            <div class="mt-5">
                <p class="text-sm text-gray-400">活動開始時間</p>
                <h5 v-if="order.started_at instanceof Date === true">
                    {{ order.started_at | formatDate }}
                    {{ order.started_at_time }}
                </h5>
                <h5 v-else>服務商到場</h5>
            </div>
            <div class="mt-5">
                <p class="text-sm text-gray-400">活動結束時間</p>
                <h5
                    v-if="
                        order.status === undefined &&
                        order.started_at instanceof Date === true
                    "
                >
                    {{
                        $moment(
                            $moment(order.started_at).format("YYYY-MM-DD ") +
                                order.started_at_time
                        )
                            .add(order.duration, "hours")
                            .format("YYYY/MM/DD HH:mm")
                    }}
                </h5>
                <h5 v-else-if="order.started_at instanceof Date === false">
                    服務商到場後計算
                </h5>
                <h5 v-else>
                    {{
                        $moment(order.started_at)
                            .add(order.duration, "hours")
                            .format("YYYY/MM/DD HH:mm")
                    }}
                </h5>
            </div>
        </div>
        <div class="mt-5">
            <p class="text-sm text-gray-400">招募截止時間</p>
            <h5>{{ order.due_at | formatDate }} {{ order.due_at_time }}</h5>
        </div>
        <div class="mt-5">
            <p class="text-sm text-gray-400">活動地點</p>
            <h5>
                {{ $checkLang(langDatas, "areas", order.district) }} |
                {{ order.location }}
            </h5>
        </div>
        <div class="mt-5">
            <p class="text-sm text-gray-400">活動詳細內容</p>
            <h5 class="break-all">{{ order.description }}</h5>
        </div>
        <div class="mt-5">
            <p class="text-sm text-gray-400">隱藏會員資訊</p>
            <h5 class="break-all">
                {{ order.anonymous === 1 ? "隱藏" : "非隱藏" }}
            </h5>
        </div>
    </section>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { rightNowActivityStatusByMember, rightNowActivityStatusByProviderTitle } from "@/config/orderConfig.js";
import store from "@/store";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.props = {
  order: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const langDatas = ref(store.state.i18nStore.langDatas);
  const isProvider = ref(store.state.userStore.isProvider);
  const props = __props;
  const statusText = computed(() => {
    return rightNowActivityStatusByMember[props.order.status];
  });
  const myStatusText = computed(() => {
    return rightNowActivityStatusByProviderTitle[props.order.my_enrolled_status];
  });
  return {
    langDatas,
    isProvider,
    statusText,
    myStatusText
  };
};
export default __sfc_main;
</script>
