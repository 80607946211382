var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"flex overflow-x-scroll text-sm hideScrollbar"},_vm._l((_vm.choosePrice),function(price,index){return _c('li',{key:price.value,staticClass:"mt-2 flex-1 md:min-w-[100px] md:max-w-[100px]",class:[
            index !== _vm.choosePrice.length - 1 ? 'mr-2' : '',
            _vm.choosePrice.length === 1
                ? 'flex justify-center min-w-[100px] max-w-[100px]'
                : 'w-full  ',
        ]},[_c('button',{staticClass:"px-2 py-1 text-xs border border-red-500 rounded whitespace-nowrap md:w-full",class:[
                _vm.currentPrice == price.value
                    ? 'bg-red-500 text-white'
                    : 'text-red-500',
                _vm.choosePrice.length === 1
                    ? 'min-w-[100px] max-w-[100px]'
                    : 'w-full',
            ],on:{"click":function($event){$event.preventDefault();return _vm.changePrice(price.value)}}},[_vm._v(" "+_vm._s(price.label)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }