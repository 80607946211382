<template>
    <section class="px-10 py-2 my-1 bg-white md:rounded-lg">
        <div class="flex">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <h5 class="text-base font-bold">價格</h5>
        </div>
        <div class="flex mt-2">
            <span class="flex-grow">出席鐘點費</span>
            <span
                >${{ form.hourly_pay | formatCurrency }} /
                {{ form.unit === "hour" ? "小時" : "天數" }}</span
            >
        </div>
        <div class="flex pb-2 mt-2 border-b border-gray-200">
            <span class="flex-grow"
                >活動{{ form.unit === "hour" ? "時數" : "天" }}</span
            >
            <span
                >x {{ form.duration }}
                {{ form.unit === "hour" ? "小時" : "天" }}</span
            >
        </div>
        <div class="flex mt-2">
            <span class="flex-grow">合計</span>
            <span>$ {{ singleOrderTotal | formatCurrency }}</span>
        </div>
        <div v-if="!salesToPanelFee" class="flex mt-2">
            <span class="flex-grow">平台服務費</span>
            <span
                >$
                {{
                    !salesToPanelFee ? singleOrderFee : 0 | formatCurrency
                }}</span
            >
        </div>
        <div class="flex mt-2">
            <span class="flex-grow">需求人數</span>
            <span>x {{ form.provider_required }} 人</span>
        </div>
        <div v-if="usePayVoucher === 1" class="flex mt-2">
            <span class="flex-grow">快閃折抵金</span>
            <span>-$ {{ discountPoint | formatCurrency }}</span>
        </div>
        <div class="flex my-2 font-bold">
            <h6 class="flex-grow text-gray-500">總付款金額</h6>
            <h6 class="text-red-500">$ {{ total | formatCurrency }}</h6>
        </div>
    </section>
</template>
<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { isEmpty } from "@/service/anyService";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  form: {
    type: Object,
    required: true
  },
  usePayVoucher: {
    type: Number,
    required: true
  },
  userData: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const isProvider = ref(store.state.userStore.isProvider);
  const props = __props;
  // 單一訂單費用總計
  const singleOrderTotal = computed(() => {
    return parseInt(props.form.hourly_pay) * parseInt(props.form.duration);
  });

  // 判斷是否有開啟平台服務費折抵
  const salesToPanelFee = computed(() => store.state.utilityStore.uiSwitchSettingDatas.enable_sales_to_fee);

  // 單一平台服務費
  const singleOrderFee = computed(() => {
    if (salesToPanelFee.value) {
      return 0;
    }
    return Math.ceil(parseInt(props.form.hourly_pay) * parseInt(props.form.duration) * process.env.VUE_APP_PANEL_FEE);
  });
  // 訂單總額
  const total = computed(() => {
    let total = parseInt(props.form.hourly_pay) * parseInt(props.form.duration) * parseInt(props.form.provider_required);
    total = total + singleOrderFee.value * props.form.provider_required - (props.usePayVoucher === 1 ? discountPoint.value : 0);
    return total;
  });
  // 快閃折抵金餘額
  const discountWalletPoint = computed(() => {
    if (isEmpty(props.userData.wallet)) {
      return 0;
    } else {
      return props.userData.wallet.voucher;
    }
  });
  // 折抵金折抵金額
  const discountPoint = computed(() => {
    const discount = parseInt(singleOrderTotal.value / 1000) * 100;
    let result = discount;
    result = result > 1000 ? 1000 : result;
    // 開單時的規則
    if (props.form.status === undefined) {
      result = result * props.form.provider_required > discountWalletPoint.value ? discountWalletPoint.value : result * props.form.provider_required;
    } else {
      // 開單後的規則
      result = props.form.details.voucherUsed;
    }
    return result;
  });
  return {
    singleOrderTotal,
    salesToPanelFee,
    singleOrderFee,
    total,
    discountPoint
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
span {
    @apply text-gray-400;
}
</style>
