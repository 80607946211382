<template>
    <div>
        <div class="mb-2">
            <h5 class="text-sm text-gray-700">其他需求備註(選填)</h5>
            <el-input
                v-model="specialNote"
                type="textarea"
                rows="2"
                placeholder="如對服務商的服裝、個性或其他方面有特別要求"
                maxlength="50"
            ></el-input>
            <div class="text-right">
                <span
                    v-if="!$isEmpty(specialNote)"
                    :class="specialNote.length > 50 ? 'text-orange-600' : ''"
                    >{{ specialNote.length }}/{{ 50 }}</span
                >
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const specialNote = ref("");
  watch(() => specialNote.value, val => {
    emit("update:specialNote", val);
  });
  return {
    specialNote
  };
};
export default __sfc_main;
</script>
