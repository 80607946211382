<template>
    <el-form-item
        v-if="form.due_at instanceof Date && form.started_at_time !== undefined"
        class="relative flex-1 ml-2"
        prop="due_at_time"
    >
        <el-time-select
            v-model="form.due_at_time"
            style="width: 100%"
            :editable="false"
            :picker-options="timeOption"
            :clearable="false"
            placeholder="選擇截止時間"
        >
        </el-time-select>
        <p
            class="absolute top-0 h-[32px] flex items-center right-2 text-gray-300"
        >
            時間
        </p>
    </el-form-item>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import moment from "moment";
const __sfc_main = {};
__sfc_main.props = {
  form: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  // 可選招募時間範圍
  let timeOption = ref({});

  // 可選的招募開始時間
  const canSelectDueTime = computed(() => {
    // 選擇招募日期時間轉換成 unix time
    const dueDay = moment(props.form.due_at).valueOf();
    // 當下時間 unix time
    let today = moment().format("YYYY-MM-DD");
    today = moment(today).valueOf();
    // 當下時間 往後一小時選擇時間
    const addOneHour = moment().add(1, "hours").hours();
    // 當選擇日期大於今日時 可以從 00:00開始選擇
    if (dueDay > today) {
      console.log("work due day");
      return "00:00";
    }
    // 當下時間往後一小時 數字小於10 時 要補零
    if (addOneHour < 10) {
      console.log("work1", addOneHour);
      return `0${addOneHour}:00`;
    }
    console.log("work2");
    return `${addOneHour}:00`;
  });
  // 可選的招募截止時間
  const canSelectEndTime = computed(() => {
    // 選擇活動日期時間轉換成 unix time
    const activityDay = moment(props.form.started_at).valueOf();
    // 選擇招募日期時間轉換成 unix time
    const dueDay = moment(props.form.due_at).valueOf();
    // 當下時間 unix time
    let today = moment().format("YYYY-MM-DD");
    today = moment(today).valueOf();

    // 取得活動開始的時辰
    const activityTime = parseInt(props.form.started_at_time.slice(0, 2));
    // 判斷招募截止日期小於今天時 或者小於 活動開始日期時 可選時辰可到 23:00
    if (dueDay < today || dueDay < activityDay) {
      console.log("work3");
      return "23:00";
    }
    // 判斷招募日期與活動日期相同時
    if (dueDay === activityDay || activityDay > today) {
      // 判斷當招募日期大於今日時 要往前一小時招募時間
      if (dueDay > today) {
        // 當下時間往後一小時 數字小於10 時 要補零
        if (activityTime < 10) {
          return `0${activityTime - 1}:00`;
        }
        return `${activityTime - 1}:00`;
      }
      return props.form.started_at_time;
    }
    if (activityDay > today) {
      // 當下時間往後一小時 數字小於10 時 要補零
      if (activityTime < 10) {
        return `0${activityTime - 1}:00`;
      }
      return `${activityTime - 1}:00`;
    }
    console.log("work4", activityDay > today, props.form.started_at_time);
    return props.form.started_at_time;
  });
  timeOption.value = {
    start: canSelectDueTime,
    step: "01:00",
    end: canSelectEndTime
  };
  return {
    timeOption
  };
};
export default __sfc_main;
</script>
